<template>
  <div>
    <!-- Navbar Component -->
    <Navbar />

    <section class="section">
      <div class="container box">
        <div class="columns">
          <!-- Image Column -->
          <div class="column">
            <figure class="image is-4by3">
              <img
                src="../../assets/ErrorPageImage.png"
                alt="Funny 404 Image"
              />
            </figure>
          </div>
          <!-- Text Column -->
          <div
            class="column has-text-left is-flex is-flex-direction-column is-justify-content-center"
          >
            <h1 class="title">Error 404 - Page Not Found</h1>
            <p class="subtitle mt-5">
              Oops! It seems you've taken a wrong turn. <br />
              This page is more lost than I am without coffee on a Monday
              morning!
            </p>
            <router-link to="/" class="button is-primary mt-5"
              >Take me home</router-link
            >
          </div>
        </div>
      </div>
    </section>

    <!-- Footer Component -->
    <Footer />
  </div>
</template>
  
  <script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>
  
  <style>
/* Scale down the image if needed */
.image img {
  max-width: 80%;
}
</style>
  