// Importing required modules for routing
import { createRouter, createWebHistory } from 'vue-router'
// Importing different page components
import LoginPage from '@/views/Auth/LoginPage.vue'
import ForgotPasswordPage from '@/views/Auth/ForgotPasswordPage.vue'
import DashboardPage from '@/views/Admin/Dashboard.vue'
import TermsAndConditionsPage from '@/views/Shared/TermsAndConditionsPage.vue'
import CookiePolicy from '@/views/Shared/CookiePolicy.vue'
import PrivacyPolicy from '@/views/Shared/PrivacyPolicy.vue'
import Accounts from '@/views/Admin/Accounts.vue'
import Profile from '@/views/Admin/Profile.vue'
import Support from '@/views/Admin/Support.vue'
import ErrorPage from '@/views/Shared/ErrorPage.vue'
import AllTransactions from '@/views/Admin/AllTransactions.vue'

// Importing the Vuex store
import store from '@/store/index'
import SupplierDashboardVue from '@/views/Admin/SupplierDashboard.vue'
import SupplierAccountsVue from '@/views/Admin/SupplierAccounts.vue'
import AllTransactionsSupplier from '@/views/Admin/AllTransactionsSupplier.vue'
import AnalyticsPage from '@/views/Admin/AnalyticsPage.vue'

// Navigation guard to check if the user is authenticated
const requireAuth = (to, from, next) => {
  // //console.log('Require Auth -->', store.getters.isAuthenticated)
  if (store.getters.isAuthenticated) {
    next(); // Proceed to the next middleware if authenticated
  } else {
    next('/login'); // Redirect to login if not authenticated
  }
}

// Navigation guard to prevent authenticated users from accessing unauthenticated routes
const requireNoAuth = (to, from, next) => {
  // //console.log('No Auth Required --> ', store.getters.isAuthenticated)
  if (store.getters.isAuthenticated) {
    if (store.getters.isCustomer) {
      next('/dashboard'); // Redirect to dashboard if already authenticated
    } else {
      next('/supplier/dashboard'); // Redirect to dashboard if already authenticated
    }

  } else {
    next(); // Proceed to the next middleware if not authenticated
  }
}

// Define routes for the application
const routes = [
  // Unauthenticated Routes
  // Login Page
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    beforeEnter: requireNoAuth, // Apply navigation guard
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordPage,
    beforeEnter: requireNoAuth, // Apply navigation guard
  },
  // Authenticated Routes
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/supplier/dashboard',
    name: 'SupplierDashboard',
    component: SupplierDashboardVue,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditionsPage,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/cookies-policy',
    name: 'CookiesPolicy',
    component: CookiePolicy,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/supplier/accounts',
    name: 'SupplierAccounts',
    component: SupplierAccountsVue,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/support',
    name: 'Support',
    component: Support,
    beforeEnter: requireAuth, // Apply navigation guard
  },
  {
    path: '/customer-analytics',
    name: 'CustomerAnalytics',
    component: AnalyticsPage,
    beforeEnter: requireAuth,
  },
  {
    path: '/all-transactions/:customer_id',
    name: 'AllTransactions',
    component: AllTransactions,
    beforeEnter: requireAuth,
    props: true // Apply navigation guard
  },
  {
    path: '/all-transactions-supplier/:group_id',
    name: 'AllTransactionsSupplier',
    component: AllTransactionsSupplier,
    beforeEnter: requireAuth,
    props: true // Apply navigation guard
  },

  // Redirects
  {
    path: '/',
    redirect: '/login' // Redirect root path to login
  },

  // 404 Error
  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: ErrorPage,
    beforeEnter: requireAuth
  }
]

// Create and configure the router
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // Use HTML5 history mode
  routes // Attach the defined routes
})

// Export the router for use in the main application
export default router
