<template>
  <Navbar></Navbar>

  <section class="section box container">
    <router-link to="/supplier/dashboard">
      <button class="button is-rounded is-small mb-5">
        <span class="material-icons">arrow_back_ios</span> Back
      </button>
    </router-link>

    <!-- Title and Export Button -->
    <div class="level">
      <div class="level-left">
        <h1 class="title has-text-left">All Transactions</h1>
      </div>
      <div class="level-right">
        <button class="button is-link is-rounded" @click="exportCSV">
          <span class="icon is-small">
            <i class="material-icons">import_export</i>
          </span>
          <span>Export All Transactions (.csv)</span>
        </button>
        <!-- <button class="button is-primary" @click="exportCSV">
          Export All Transactions (.csv)
        </button> -->
      </div>
    </div>

    <!-- Filters -->
    <div class="columns is-centered">
      <!-- Date Selection -->
      <div class="column is-narrow">
        <label class="label">Start Date:</label>
        <input type="date" v-model="startDate" class="input" />
      </div>
      <div class="column is-narrow">
        <label class="label">End Date:</label>
        <input type="date" v-model="endDate" class="input" />
      </div>
      <!-- Supplier Name Filter -->
      <div class="column is-half is-narrow">
        <label class="label">Supplier Name:</label>
        <VueMultiselect
          v-model="selectedSuppliers"
          :options="supplierOptions"
          :multiple="true"
          :close-on-select="true"
          placeholder="Select Suppliers"
          label="SupplierName"
          track-by="SupplierName"
          class="multiselect"
        ></VueMultiselect>
      </div>
    </div>

    <!-- Charts Section -->
    <div class="columns is-multiline">
      <!-- Volume by Month Chart -->
      <div class="column box container uniform-card">
        <p class="is-size-4">Volume by Month</p>
        <p>UCO Collection trend over months</p>
        <BarChart
          :chart-data="volumeByMonthData"
          :chart-options="chartOptions"
          :key="chartKey"
        />
      </div>
      <!-- Collections by Month Chart -->
      <div class="column box container uniform-card">
        <p class="is-size-4">Collections by Month</p>
        <p>Number of collections over months</p>
        <BarChart
          :chart-data="collectionsByMonthData"
          :chart-options="chartOptions"
          :key="chartKey"
        />
      </div>
    </div>

    <!-- Line Chart -->
    <div
      v-if="showLineChart"
      class="box container uniform-card"
      style="margin-top: 20px"
    >
      <p class="is-size-4">Volume by Supplier Over Time</p>
      <p>Volume collected by selected suppliers over time</p>
      <LineChart
        :chart-data="volumeBySupplierOverTimeData"
        :chart-options="lineChartOptions"
        :key="chartKey"
      />
    </div>

    <!-- Transactions Table -->
    <div class="box container">
      <h1 class="is-size-4 mb-5">Transaction Data</h1>
      <Vue3EasyDataTable
        v-if="filteredTransactions.length"
        header-text-direction="center"
        body-text-direction="center"
        border-cell
        table-class-name="customize-table"
        :headers="headers"
        :items="filteredTransactions"
      >
        <template #item-DateTimeOfCollection="{ DateTimeOfCollection }">
          {{ formatDate(DateTimeOfCollection) }}
        </template>
      </Vue3EasyDataTable>
    </div>
  </section>

  <Footer></Footer>
</template>


<script>
import { useStore } from "vuex";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import BarChart from "@/components/BarChart.vue";
import LineChart from "@/components/LineChart.vue";
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { onMounted, ref, watch, computed } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "AllTransactionsSupplier",
  components: {
    Navbar,
    Footer,
    Vue3EasyDataTable,
    BarChart,
    LineChart,
    VueMultiselect,
  },
  setup() {
    const route = useRoute();
    const group_id = ref(route.params.group_id);
    const transactions = ref([]);
    const endDate = ref(new Date().toISOString().substr(0, 10));

    // Set start date to 1st of the month, 6 months ago
    const today = new Date();
    const sixMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 6, 2);
    const startDate = ref(sixMonthsAgo.toISOString().substr(0, 10));

    const chartKey = ref(0);
    const store = useStore();

    const selectedSuppliers = ref([]);
    const supplierOptions = ref([]);

    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    const headers = [
      { value: "DateTimeOfCollection", text: "Date", sortable: true },
      { value: "PurchaseOrderNumber", text: "PO Number", sortable: true },
      { value: "SupplierName", text: "Supplier Name", sortable: true },
      { value: "LocationName", text: "Location Name", sortable: true },
      { value: "Quantity", text: "Volume (L)", sortable: true },
    ];

    const fetchTransactions = async () => {
      const apiUrl = store.state.apiUrl + "getAllSupplierCollectionsByGroup";
      try {
        const response = await fetch(
          `${apiUrl}/${group_id.value}?start_date=${startDate.value}&end_date=${endDate.value}`
        );
        if (response.ok) {
          const result = await response.json();
          transactions.value = result[1].collectionData;

          // Update supplier options
          const suppliersSet = new Set();
          transactions.value.forEach((transaction) => {
            if (transaction.SupplierName) {
              suppliersSet.add(transaction.SupplierName);
            }
          });
          supplierOptions.value = Array.from(suppliersSet)
            .map((name) => ({ SupplierName: name }))
            .sort((a, b) => a.SupplierName.localeCompare(b.SupplierName));
        } else {
          console.error("Error fetching transactions");
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    watch([startDate, endDate], () => {
      fetchTransactions();
    });

    const filteredTransactions = computed(() => {
      if (selectedSuppliers.value.length === 0) {
        return transactions.value;
      }
      const selectedNames = selectedSuppliers.value.map(
        (supplier) => supplier.SupplierName
      );
      return transactions.value.filter((transaction) =>
        selectedNames.includes(transaction.SupplierName)
      );
    });

    const volumeByMonthData = ref({
      labels: [],
      datasets: [
        {
          label: "Total Volume",
          backgroundColor: "#00d4ff",
          data: [],
        },
      ],
    });

    const collectionsByMonthData = ref({
      labels: [],
      datasets: [
        {
          label: "Number of Collections",
          backgroundColor: "#ff6384",
          data: [],
        },
      ],
    });

    const volumeBySupplierOverTimeData = ref({
      labels: [],
      datasets: [],
    });

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
        },
      },
      scales: {
        x: {
          beginAtZero: true,
          ticks: {
            autoSkip: false,
          },
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    const lineChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "bottom",
        },
      },
      scales: {
        x: {
          type: "time",
          time: {
            unit: "month",
            displayFormats: {
              month: "MMM yyyy",
            },
          },
          ticks: {
            autoSkip: false,
          },
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    const showLineChart = computed(() => {
      const supplierCount = selectedSuppliers.value.length;
      return supplierCount >= 1 && supplierCount <= 20;
    });

    watch(
      [filteredTransactions, selectedSuppliers],
      () => {
        const newTransactions = filteredTransactions.value;

        // Volume by Month
        const volumeByMonth = {};
        // Collections by Month
        const collectionsByMonth = {};
        // Volume by Supplier Over Time
        const volumeBySupplierOverTime = {};

        newTransactions.forEach((transaction) => {
          const date = new Date(transaction.DateTimeOfCollection);
          const monthName = `${date.toLocaleString("default", {
            month: "long",
          })} ${date.getFullYear()}`;

          // Volume by Month
          if (!volumeByMonth[monthName]) volumeByMonth[monthName] = 0;
          volumeByMonth[monthName] += transaction.Quantity;

          // Collections by Month
          if (!collectionsByMonth[monthName]) collectionsByMonth[monthName] = 0;
          collectionsByMonth[monthName] += 1;

          // Volume by Supplier Over Time
          const supplierName = transaction.SupplierName || "Unknown";
          if (!volumeBySupplierOverTime[supplierName])
            volumeBySupplierOverTime[supplierName] = {};
          const dateKey = date.toISOString().substr(0, 10);
          if (!volumeBySupplierOverTime[supplierName][dateKey])
            volumeBySupplierOverTime[supplierName][dateKey] = 0;
          volumeBySupplierOverTime[supplierName][dateKey] +=
            transaction.Quantity;
        });

        // Update Volume by Month Data
        volumeByMonthData.value.labels = Object.keys(volumeByMonth);
        volumeByMonthData.value.datasets[0].data = Object.values(volumeByMonth);

        // Update Collections by Month Data
        collectionsByMonthData.value.labels = Object.keys(collectionsByMonth);
        collectionsByMonthData.value.datasets[0].data =
          Object.values(collectionsByMonth);

        // Update Volume by Supplier Over Time Data
        if (showLineChart.value) {
          const datasets = [];
          const colors = [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
            "#FF9F40",
            "#66FF66",
            "#FF6666",
            "#66B2FF",
            "#CC99FF",
            "#FFCC99",
            "#99FFCC",
            "#FF6699",
            "#6699FF",
            "#99CC66",
            "#CC6699",
            "#66CC99",
            "#CC9933",
            "#3399CC",
            "#9933CC",
          ];

          let colorIndex = 0;

          // Collect all dates across all suppliers
          const allDatesSet = new Set();
          Object.values(volumeBySupplierOverTime).forEach((dates) => {
            Object.keys(dates).forEach((date) => allDatesSet.add(date));
          });
          const allDates = Array.from(allDatesSet).sort();

          Object.keys(volumeBySupplierOverTime).forEach((supplierName) => {
            const supplierData = [];
            allDates.forEach((date) => {
              supplierData.push(
                volumeBySupplierOverTime[supplierName][date] || 0
              );
            });

            datasets.push({
              label: supplierName,
              data: supplierData,
              borderColor: colors[colorIndex % colors.length],
              backgroundColor: colors[colorIndex % colors.length],
              fill: false,
            });

            colorIndex++;
          });

          volumeBySupplierOverTimeData.value.labels = allDates.map(
            (date) => date
          );
          volumeBySupplierOverTimeData.value.datasets = datasets;
        } else {
          volumeBySupplierOverTimeData.value = {
            labels: [],
            datasets: [],
          };
        }

        chartKey.value++;
      },
      { immediate: true }
    );

    // Export CSV Function
    const exportCSV = () => {
      let csvContent = "";

      // Add date range
      csvContent += `Date Range:,${formatDate(startDate.value)} - ${formatDate(
        endDate.value
      )}\n`;

      // Add supplier name filter
      let supplierNames;
      if (selectedSuppliers.value.length === 0) {
        supplierNames = "All Suppliers";
      } else {
        supplierNames = selectedSuppliers.value
          .map((s) => s.SupplierName)
          .join(", ");
      }
      csvContent += `Supplier Name Filter:,${supplierNames}\n\n`;

      // Add table headers
      const headerRow = headers.map((h) => h.text).join(",");
      csvContent += headerRow + "\n";

      // Add table data
      filteredTransactions.value.forEach((transaction) => {
        const row = [
          formatDate(transaction.DateTimeOfCollection),
          transaction.PurchaseOrderNumber,
          transaction.SupplierName,
          transaction.LocationName,
          transaction.Quantity,
        ];
        csvContent += row.map((value) => `"${value}"`).join(",") + "\n";
      });

      // Encode CSV content
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "all-transactions.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    onMounted(fetchTransactions);

    return {
      startDate,
      endDate,
      headers,
      formatDate,
      transactions,
      filteredTransactions,
      chartOptions,
      lineChartOptions,
      chartKey,
      volumeByMonthData,
      volumeBySupplierOverTimeData,
      collectionsByMonthData,
      selectedSuppliers,
      supplierOptions,
      showLineChart,
      exportCSV, // Make sure to return exportCSV so it can be used in the template
    };
  },
};
</script>



<style>
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.css";

.uniform-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 1%;
}

.multiselect {
  max-width: 100%;
}

.customize-table {
  --easy-table-border: 1px solid #c1cad4;
  --easy-table-row-border: 1px solid #c1cad4;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: rgb(20, 122, 190);

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #fff;
  --easy-table-body-even-row-background-color: #4c5d7a;

  --easy-table-body-row-font-color: #c0c7d2;
  --easy-table-body-row-background-color: #2d3a4f;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #c1cad4;
  --easy-table-body-row-hover-background-color: rgb(20, 122, 190);

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #2d3a4f;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;

  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}

/* Round the table borders */
.vue3-easy-data-table {
  border-radius: 20px;
  overflow: hidden; /* This ensures the inner content respects the border-radius */
}

/* Additionally, if the table has a border, round its top-left and top-right corners */
.vue3-easy-data-table thead th:first-child {
  border-top-left-radius: 20px;
}

.vue3-easy-data-table thead th:last-child {
  border-top-right-radius: 20px;
}
</style>
