<template>
  <navbar></navbar>
  <section class="section" v-if="isLoading">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />
  </section>

  <section class="section box container tree-cards" v-if="!isLoading">
    <div v-if="userProfile">
      <div
        class="container is-flex is-align-items-center is-justify-content-space-between"
      >
        <h1 class="title">Dashboard</h1>
        <figure class="image is-96x96">
          <img
            v-if="userProfile.customer_logo != 'URL'"
            :src="userProfile.customer_logo"
            alt="Customer Logo"
          />
        </figure>
      </div>

      <div class="container">
        <div class="columns is-centered">
          <div class="column is-narrow">
            <label class="label">Start Date:</label>
            <input type="date" v-model="startDate" class="input" />
          </div>
          <div class="column is-narrow">
            <label class="label">End Date:</label>
            <input type="date" v-model="endDate" class="input" />
          </div>
          <div class="column is-half is-narrow">
            <label class="label">Supplier Name:</label>
            <VueMultiselect
              v-model="selectedSuppliers"
              :options="supplierOptions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Select Suppliers"
              label="SupplierName"
              track-by="SupplierName"
              class="multiselect"
            ></VueMultiselect>
          </div>
        </div>
      </div>

      <div class="cards-section">
        <div
          class="container box"
          style="margin: 1%"
          v-for="(group, index) in userProfile.group"
          :key="index"
        >
          <h1 class="title has-text-left">
            {{ group.group_name }} - {{ group.site_name }}
          </h1>

          <div
            class="columns is-multiline"
            v-if="!isLoading && transactions.length"
          >
            <div class="column is-one-fourth">
              <div class="card has-text-centered uniform-card">
                <div class="card-content">
                  <p class="dynamic-font-size">
                    {{
                      calculateSummary(filteredTransactions(index))
                        .NoOfActiveSuppliers
                    }}
                  </p>
                  <p class="is-size-6">No. of Active Suppliers</p>
                </div>
              </div>
            </div>
            <div class="column is-one-fourth">
              <div class="card has-text-centered uniform-card">
                <div class="card-content">
                  <p class="dynamic-font-size">
                    {{
                      calculateSummary(filteredTransactions(index))
                        .NoOfUCOCollections
                    }}
                  </p>
                  <p class="is-size-6">No. of UCO Collections</p>
                </div>
              </div>
            </div>
            <div class="column is-one-fourth">
              <div class="card has-text-centered uniform-card">
                <div class="card-content">
                  <p class="dynamic-font-size">
                    {{
                      calculateSummary(filteredTransactions(index))
                        .TotalUCOCollectedFormatted
                    }}
                  </p>
                  <p class="is-size-6">Total UCO Collected<br />(L)</p>
                </div>
              </div>
            </div>
            <div class="column is-one-fourth">
              <div class="card has-text-centered uniform-card">
                <div class="card-content">
                  <p class="dynamic-font-size">
                    {{
                      calculateSummary(filteredTransactions(index))
                        .FacilitatedSavingsFormatted
                    }}
                  </p>
                  <p class="is-size-6">Facilitated Savings<br />(KgCo2eq)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section box container" v-if="!isLoading">
    <div v-if="userProfile">
      <div
        class="container is-flex is-justify-content-space-between is-align-items-center"
      >
        <h1 class="title">Collection Summary</h1>

        <div class="buttons are-small">
          <router-link
            :to="{
              name: 'AllTransactionsSupplier',

              params: {
                group_id: userProfile.group[transactionsIndex].group_id,
              },
            }"
          >
            <button class="button is-rounded">
              <span class="icon is-small">
                <i class="material-icons">visibility</i>
              </span>
              <span class="ml-1">View All Transactions</span>
            </button>
          </router-link>
          <button
            class="button is-link is-rounded ml-3"
            @click="exportSummaryToCSV"
          >
            <span class="icon is-small">
              <i class="material-icons">import_export</i>
            </span>
            <span>Export Collection Summary (.csv)</span>
          </button>
        </div>
      </div>

      <div class="select is-rounded mb-5">
        <select name="Select Account" id="" v-model="transactionsIndex">
          <option disabled value="">Select an account</option>
          <option
            :value="index"
            v-for="(customer, index) in userProfile.group"
            :key="index"
          >
            {{ customer.group_name }} - {{ customer.site_name }}
          </option>
        </select>
      </div>

      <Vue3EasyDataTable
        v-if="filteredSummary && filteredSummary.length > 0"
        header-text-direction="center"
        body-text-direction="center"
        border-cell
        table-class-name="customize-table"
        :headers="headers"
        :items="filteredSummary"
      ></Vue3EasyDataTable>
    </div>
  </section>
  <Footer></Footer>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import VueMultiselect from "vue-multiselect";

import { computed, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";

export default {
  components: { Navbar, Loading, Footer, Vue3EasyDataTable, VueMultiselect },
  setup() {
    const isLoading = ref(false);
    const store = useStore();

    // Adjusted startDate to be the first day of the month, six months ago
    const startDate = ref(
      new Date(new Date().getFullYear(), new Date().getMonth() - 6, 2)
        .toISOString()
        .substr(0, 10)
    );
    const endDate = ref(new Date().toISOString().substr(0, 10));
    const selectedSuppliers = ref([]);

    const transactionsIndex = ref(0);
    const transactions = ref([]);
    const transactionSummary = ref([]);

    const userProfile = computed(() => store.state.userProfile);

    const supplierOptions = computed(() => {
      const suppliers = [];
      transactionSummary.value.forEach((summary) => {
        summary.forEach((transaction) => {
          if (
            !suppliers.some(
              (supplier) => supplier.SupplierName === transaction.SupplierName
            )
          ) {
            suppliers.push({
              SupplierName: transaction.SupplierName,
            });
          }
        });
      });
      // Sort suppliers A-Z
      suppliers.sort((a, b) => a.SupplierName.localeCompare(b.SupplierName));
      return suppliers;
    });

    const headers = [
      { value: "SupplierName", text: "Supplier Name", sortable: true },
      {
        value: "LocationName",
        text: "Location Name",
        sortable: true,
      },
      {
        value: "TotalUCOCollections",
        text: "No. of UCO Collections",
        sortable: true,
      },
      {
        value: "TotalUCOCollectedFormatted",
        text: "Total UCO Collected (L)",
        sortable: true,
      },
      {
        value: "FacilitatedSavingsFormatted",
        text: "Facilitated Savings (KgCo2eq)",
        sortable: true,
      },
    ];

    const formatNumber = (num) => {
      return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const formatNumberWithSuffix = (num) => {
      if (num >= 1000000) return (num / 1000000).toFixed(2) + " Mil";
      else if (num >= 1000) return (num / 1000).toFixed(2) + " K";
      return num.toFixed(2);
    };

    const fetchGroupSummary = async (apiUrl, group_id) => {
      const response = await fetch(
        `${apiUrl}/${group_id}?start_date=${startDate.value}&end_date=${endDate.value}`
      );
      if (response.ok) {
        const result = await response.json();
        result[1].groupSummary.forEach((summary) => {
          if (summary.TotalUCOCollected !== undefined) {
            summary.FacilitatedSavings =
              summary.TotalUCOCollected * 0.94 * 3.00403377;
            summary.TotalUCOCollectedFormatted = formatNumberWithSuffix(
              summary.TotalUCOCollected
            );
            summary.FacilitatedSavingsFormatted = formatNumberWithSuffix(
              summary.FacilitatedSavings
            );
          }
        });
        // Sort suppliers A-Z
        result[1].groupSummary.sort((a, b) =>
          a.SupplierName.localeCompare(b.SupplierName)
        );
        return result[1].groupSummary;
      } else {
        console.error("Error fetching collection summary");
        return [];
      }
    };

    const fetchTransactions = async (group_ids) => {
      isLoading.value = true;
      try {
        const fetchPromises = group_ids.map(async (group_id, i) => {
          // Fetch data from the API
          const groupData = await fetchGroupSummary(
            store.state.apiUrl + "getSupplierCollectionSummaryByGroup",
            group_id
          );
          // Save the data
          transactionSummary.value[i] = groupData;

          // Now compute the summary data for the dashboard cards
          const summary = calculateSummary(groupData);
          // Store the summary data for the dashboard cards
          transactions.value[i] = summary;
        });
        await Promise.all(fetchPromises);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const filteredTransactions = (index) => {
      if (selectedSuppliers.value.length === 0) {
        return transactionSummary.value[index];
      }
      return transactionSummary.value[index].filter((transaction) =>
        selectedSuppliers.value.some(
          (supplier) => supplier.SupplierName === transaction.SupplierName
        )
      );
    };

    const filteredSummary = computed(() => {
      if (
        !transactionSummary.value[transactionsIndex.value] ||
        transactionSummary.value[transactionsIndex.value].length === 0
      ) {
        return [];
      }
      if (selectedSuppliers.value.length === 0) {
        return transactionSummary.value[transactionsIndex.value];
      }
      return transactionSummary.value[transactionsIndex.value].filter(
        (transaction) =>
          selectedSuppliers.value.some(
            (supplier) => supplier.SupplierName === transaction.SupplierName
          )
      );
    });

    const calculateSummary = (transactionsData) => {
      const summary = {
        NoOfActiveSuppliers: 0,
        NoOfUCOCollections: 0,
        TotalUCOCollected: 0,
        FacilitatedSavings: 0,
        TotalUCOCollectedFormatted: "",
        FacilitatedSavingsFormatted: "",
      };

      const supplierIds = new Set();

      transactionsData.forEach((transaction) => {
        summary.NoOfUCOCollections += transaction.TotalUCOCollections || 0;
        summary.TotalUCOCollected += transaction.TotalUCOCollected || 0;

        if (!transaction.FacilitatedSavings) {
          transaction.FacilitatedSavings =
            transaction.TotalUCOCollected * 0.94 * 3.00403377;
        }
        summary.FacilitatedSavings += transaction.FacilitatedSavings || 0;

        supplierIds.add(transaction.SupplierId);
      });

      summary.NoOfActiveSuppliers = supplierIds.size;
      summary.TotalUCOCollectedFormatted = formatNumberWithSuffix(
        summary.TotalUCOCollected
      );
      summary.FacilitatedSavingsFormatted = formatNumberWithSuffix(
        summary.FacilitatedSavings
      );

      return summary;
    };

    // Implement the exportSummaryToCSV method
    const exportSummaryToCSV = () => {
      let csvContent = "";

      // Add date range
      csvContent += `Date Range:,${startDate.value} - ${endDate.value}\n`;

      // Add supplier name filter
      let supplierNames;
      if (selectedSuppliers.value.length === 0) {
        supplierNames = "All Suppliers";
      } else {
        supplierNames = selectedSuppliers.value
          .map((s) => s.SupplierName)
          .join(", ");
      }
      csvContent += `Supplier Name Filter:,${supplierNames}\n\n`;

      // Add dashboard cards details
      const currentTransactions = transactions.value[transactionsIndex.value];
      csvContent += "Dashboard Summary\n";
      csvContent +=
        "No. of Active Suppliers,No. of UCO Collections,Total UCO Collected (L),Facilitated Savings (KgCo2eq)\n";
      csvContent += `${currentTransactions.NoOfActiveSuppliers},${currentTransactions.NoOfUCOCollections},${currentTransactions.TotalUCOCollected},${currentTransactions.FacilitatedSavings}\n\n`;

      // Add table headers
      csvContent += "Collection Summary Table\n";
      const headerRow = headers.map((h) => h.text).join(",");
      csvContent += headerRow + "\n";

      // Add table data
      filteredSummary.value.forEach((transaction) => {
        const row = [
          transaction.SupplierName,
          transaction.LocationName,
          transaction.TotalUCOCollections,
          transaction.TotalUCOCollected,
          transaction.FacilitatedSavings,
        ];
        csvContent += row.map((value) => `"${value}"`).join(",") + "\n";
      });

      // Encode CSV content
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "collection_summary.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    };

    watchEffect(() => {
      if (userProfile.value) {
        const groupIds = userProfile.value.group.map((group) => group.group_id);
        fetchTransactions(groupIds);
      }
    });

    watch([startDate, endDate], () => {
      const groupIds = userProfile.value.group.map((group) => group.group_id);
      fetchTransactions(groupIds);
    });

    watch(selectedSuppliers, () => {
      // Update transactions when selected suppliers change
      transactions.value = userProfile.value.group.map((group, index) =>
        calculateSummary(filteredTransactions(index))
      );
    });

    return {
      isLoading,
      userProfile,
      startDate,
      endDate,
      selectedSuppliers,
      supplierOptions,
      filteredTransactions,
      filteredSummary,
      calculateSummary,
      transactionsIndex,
      transactions,
      transactionSummary,
      headers,
      exportSummaryToCSV, // Include the method in the returned properties
    };
  },
};
</script>



<style>
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.css";

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.uniform-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.uniform-card .card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dynamic-font-size {
  font-size: calc(1rem + 0.8vw);
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tree-cards {
  background-image: url("../../assets/sky.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.customize-table {
  --easy-table-border: 1px solid #c1cad4;
  --easy-table-row-border: 1px solid #c1cad4;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: rgb(20, 122, 190);

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #fff;
  --easy-table-body-even-row-background-color: #4c5d7a;

  --easy-table-body-row-font-color: #c0c7d2;
  --easy-table-body-row-background-color: #2d3a4f;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #c1cad4;
  --easy-table-body-row-hover-background-color: rgb(20, 122, 190);

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #2d3a4f;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;

  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}

/* Round the table borders */
.vue3-easy-data-table {
  border-radius: 20px;
  overflow: hidden; /* This ensures the inner content respects the border-radius */
}

/* Additionally, if the table has a border, round its top-left and top-right corners */
.vue3-easy-data-table thead th:first-child {
  border-top-left-radius: 20px;
}

.vue3-easy-data-table thead th:last-child {
  border-top-right-radius: 20px;
}

.multiselect {
  max-width: 75vh;
}
</style>
