<template>
  <nav
    class="navbar is-fixed-top is-black"
    role="navigation"
    aria-label="main navigation"
    v-if="userProfile"
  >
    <div class="navbar-brand">
      <router-link
        class="navbar-item"
        to="/dashboard"
        v-if="userProfile.account_type === 'CUSTOMER'"
      >
        <img src="../assets/earth.png" alt="Company Logo" />
        <h1 class="ml-2"><b>myPortal</b></h1>
      </router-link>

      <router-link
        class="navbar-item"
        to="/supplier/dashboard"
        v-if="userProfile.account_type === 'SUPPLIER'"
      >
        <img src="../assets/earth.png" alt="Company Logo" />
        <h1 class="ml-2"><b>myPortal</b></h1>
      </router-link>

      <!-- Hamburger menu -->
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbar-menu"
        @click="toggleMenu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': menuActive }">
      <div class="navbar-start" v-if="userProfile">
        <router-link
          v-if="userProfile.account_type === 'CUSTOMER'"
          to="/dashboard"
          class="navbar-item"
          active-class="is-active"
          >Dashboard</router-link
        >

        <router-link
          v-if="userProfile.account_type === 'CUSTOMER'"
          to="/customer-analytics"
          class="navbar-item"
          active-class="is-active"
          >Analytics</router-link
        >

        <router-link
          v-if="userProfile.account_type === 'SUPPLIER'"
          to="/supplier/dashboard"
          class="navbar-item"
          active-class="is-active"
          >Dashboard</router-link
        >

        <router-link
          to="/accounts"
          class="navbar-item"
          active-class="is-active"
          v-if="
            userProfile.role == 'ACCOUNT_ADMIN' &&
            userProfile.account_type === 'CUSTOMER'
          "
          >Users</router-link
        >

        <router-link
          to="/supplier/accounts"
          class="navbar-item"
          active-class="is-active"
          v-if="
            userProfile.role == 'ACCOUNT_ADMIN' &&
            userProfile.account_type === 'SUPPLIER'
          "
          >Users</router-link
        >
        <router-link to="/profile" class="navbar-item" active-class="is-active"
          >Profile</router-link
        >
        <!-- <router-link to="/support" class="navbar-item" active-class="is-active"
          >Support</router-link
        > -->
      </div>

      <div v-if="userProfile" class="navbar-end">
        <div class="navbar-item">
          Curently Logged in as {{ userProfile.first_name }} (<span
            class="is-danger"
            ><b>{{ userProfile.role }}</b></span
          >)
        </div>
        <div class="navbar-item">
          <button class="button is-danger is-rounded is-small" @click="logout">
            Logout
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
    
    <script>
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default {
  setup() {
    const router = useRouter();
    const store = useStore();
    const menuActive = ref(false); // State to track whether the menu is open

    const logout = async () => {
      await store.dispatch("logout");
      router.push({ name: "Login" });
    };

    const toggleMenu = () => {
      menuActive.value = !menuActive.value; // Toggle the menu
    };

    return {
      logout,
      menuActive,
      toggleMenu,
      userProfile: computed(() => store.state.userProfile),
    };
  },
};
</script>
    
    <style>
/* Style for the active navbar item */
.navbar-item.is-active {
  background-color: #6289ff;
}
</style>
    