<template>
  <div>
    <!-- Navbar Component -->
    <Navbar />

    <section class="section">
      <div class="container box">
        <h1 class="title">Cookies Ploicy</h1>
        <div class="content">
          <p>
            The Neutral Fuels Customer Portal www.myportal.neutralfuels.com (the
            “Portal”) is owned and operated by Neutral Fuels LLC (“Neutral
            Fuels”, “we”, “us”, or “our”). This cookies policy (“Portal Cookies
            Policy”) explains how and why cookies, web beacons, pixels, clear
            gifs, and other similar technologies (collectively “Cookies”) may be
            stored on and accessed from your device when you use or visit the
            Portal. This Portal Cookies Policy should be read together with our
            Portal Privacy Policy and our Portal Terms.
          </p>
          <p>
            Please read this Portal Cookies Policy carefully. By accessing and
            continuing to use this Portal, you confirm that you have read,
            understood, and agree to be bound by this Portal Cookies Policy in
            its entirety.
          </p>

          <h2 class="subtitle">What is a cookie?</h2>
          <p>
            A cookie is a small amount of data that is placed in the browser of
            your computer or on your mobile device. This Policy applies to
            Cookies and similar technologies.
          </p>
          <p>
            Cookies can be categorised according to their function, their
            lifespan and according to who places them on a website.
          </p>

          <h2 class="subtitle">‍Why are cookies used?</h2>
          <p>
            If you are surfing from page to page within a website, you will not
            be recognised as the same user across pages. Cookies enable your
            browser to be recognised by the website. So, cookies are mainly used
            to remember the choices you have made – choices such as the language
            you prefer and the currency you use. They will also make sure you
            are recognised when you return to a website.
          </p>

          <h2 class="subtitle">How are cookies used?</h2>
          <p>Our Portal uses the following types of cookies:</p>
          <ul>
            <li>
              <b>Technical cookies:</b>
              Neutral Fuels try to provide our visitors with an advanced and
              user-friendly Portal that adapts automatically to their needs and
              wishes. To achieve this, Neutral Fuels use technical cookies to
              show you the Portal and to make it function correctly. These
              technical cookies are absolutely necessary for the Portal to
              function properly.
            </li>
            <li>
              <b>Functional cookies:</b>
              Neutral Fuels use functional cookies to remember your preferences
              and to help you use the Portal efficiently and effectively. These
              functional cookies are not strictly necessary for the functioning
              of the Portal, but they add functionality for you and enhance your
              experience.
            </li>
            <li>
              <b>Analytics cookies:</b>
              Neutral Fuels use these cookies to gain insight on how our
              visitors use the Portal, to find out what works and what doesn’t,
              to optimize and improve the Portal and to ensure Neutral Fuels
              continue to be interesting and relevant. The data we gather
              includes which pages you viewed, which referring/exit pages you
              entered and left from, which platform type you used, date and time
              stamp information and details such as the number of clicks you
              make on a given page, your mouse movements and scrolling activity,
              the search words you use and the text you type while using the
              Portal.
            </li>
            <li>
              <b>Commercial cookies:</b>
              Neutral Fuels use these to show you advertisements on other
              Websites. This is called “retargeting” and it aims to be based on
              your browsing activities on the Portal.
            </li>
          </ul>

          <h2 class="subtitle">
            How long does the Site’s cookies stay active?
          </h2>
          <p>
            The Cookies we use have varying lifespans. The maximum lifespan
            Neutral Fuels set on some is five years from your last visit to the
            Portal.
          </p>

          <h2 class="subtitle">
            How can you recognise and delete the Site’s cookies?
          </h2>
          <p>
            You can find our Cookies in your browser settings. If you wish to
            restrict or block the Cookies which are set by the Portal, or indeed
            any other website, you can do this through your browser settings any
            time you want. Please be aware that restricting Cookies may impact
            on the functionality of the Portal.
          </p>
          <p>
            If you wish to view your cookie code, just click on a cookie to open
            it. You'll see a short string of text and numbers. The numbers are
            your identification card, which can only be seen by the server that
            gave you the cookie.
          </p>
          <p>
            For information on how to do this on the browser of your mobile
            phone you will need to refer to your handset manual.
          </p>
          <p>
            For any questions, please email us at
            <a href="mailto:info@neutralfuels.com">info@neutralfuels.com</a>.
            Updated 2023.
          </p>
        </div>
      </div>
    </section>

    <!-- Footer Component -->
    <Footer />
  </div>
</template>
  
  <script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>
  
  <style>
/* Additional styling if needed */
</style>
  