<template>
  <div>
    <!-- Navbar Component -->
    <Navbar />

    <section class="section">
      <div class="container box">
        <h1 class="title">Privacy Policy</h1>
        <div class="content">
          <p>
            The Neutral Fuels Customer Portal www.myportal.neutralfuels.com (the
            “Portal”) is owned and operated by Neutral Fuels LLC (“Neutral
            Fuels”, “we”, “us”, or “our”). This privacy policy (the “Portal
            Privacy Policy”) sets out the basis on which we collect and use any
            personal data we collect from customers and users of the Portal
            (“you” or “your”), or that you provide to us.
          </p>
          <p>
            Please read this Portal Privacy Policy carefully. By accessing and
            continuing to use this Portal, you confirm that you have read,
            understood, and agree to be bound by this Policy in its entirety and
            that you give us your consent to collect and process your personal
            data as set out in this Policy. If you do not accept the terms of
            this Portal Privacy Policy, then you must stop using or accessing
            this Portal.
          </p>
          <p>
            For the purposes of applicable data protection law (including the
            UAE Federal Decree-law No 45 of 2021 on Personal Data Protection),
            “personal data” is any information that relates to you, and which,
            alone or in combination with other information, could reasonably be
            used to identify you such as your name, voice, picture,
            identification number, electronic identifier, geographical location,
            or physical, physiological, economic, cultural, or social
            characteristics. Neutral Fuels is the data “controller” of any
            personal data provided to us. Specifically, your data will be
            controlled by Neutral Fuels.
          </p>
          <p>
            The Privacy Policy describes how we use and process your personal
            data. It also tells you how you can contact us if you have questions
            about your personal data. Neutral Fuels may amend the Portal Privacy
            Policy from time to time, so you should check this page from time to
            time to ensure that you are aware of any changes that have been
            made.
          </p>
          <p>
            Neutral Fuels respects your right to privacy and are committed to
            responsible information handling practices. We value your trust, and
            we are committed to protecting and safeguarding any personal
            information you give us.
          </p>

          <h2 class="subtitle">What Kind of Data Does the Portal Use?</h2>
          <p>
            When you access the Portal, you’ll be asked for your name, email
            address and contact information. By accessing the Portal you consent
            to the collection, use and storage of this personal data as set out
            herein.
          </p>
          <p>
            When you visit our Portal, Neutral Fuels may also collect certain
            information, like your IP address, or browser, and information about
            your computer’s operating system, application version, language
            settings and pages that have been shown to you. Neutral Fuels may
            passively collect information regarding country and time of access
            through use of Google Analytics. This information is not stored, and
            no personal data (such as your name) is collected. The service
            providers that administer these services use technologies such as
            cookies to help analyse how visitors use our Portal. The information
            collected through these means (including IP address) is disclosed to
            or collected directly by these service providers, who use the
            information to evaluate the use of the Portal or to provide
            interest-based advertising to you. This Portal Privacy Policy should
            be read in conjunction with our Portal Cookies Policy.
          </p>
          <p>
            If you’re using a mobile device, Neutral Fuels might also collect
            data that identifies your mobile device, device-specific settings
            and characteristics and latitude/longitude details. Neutral Fuels
            treats this data as non-personal information, except where Neutral
            Fuels are compelled to do otherwise by law or legal authority.
          </p>
          <p>
            Neutral Fuels may also receive information about you when you use
            certain social media services.
          </p>
          <p>
            Neutral Fuels may link to other websites which are not within our
            control. Once you have left the Portal, Neutral Fuels are not
            responsible for the protection and privacy of any data which you
            provide. You should exercise caution and look at the privacy
            statement applicable to the third party’s website in question.
          </p>

          <h2 class="subtitle">Processing Your Personal Data</h2>
          <p>
            Neutral Fuels uses your personal data to provide you with a better
            service, and in particular for the following reasons:
          </p>
          <ul>
            <li>to communicate with you;</li>
            <li>
              to provide you with services you have requested and notify you
              about important changes or developments to these services;
            </li>
            <li>
              to respond to your inquiries and/or complaints or process your
              requests in relation to your data;
            </li>
            <li>to follow up as part of our customer care procedures;</li>
            <li>to process transactions;</li>
            <li>to update our records about you;</li>
            <li>for internal record keeping;</li>
            <li>
              to contact you for market research purposes and/or administer
              offers to you;
            </li>
            <li>to provide, maintain and improve our products and services;</li>
            <li>
              for fraud prevention and detection, and to comply with applicable
              laws and regulations;
            </li>
            <li>in relation to competitions and other promotions;</li>
            <li>
              to evaluate the effectiveness of our marketing and for research,
              training, and statistical analysis with the aim of improving our
              services; and
            </li>
            <li>
              to make our Portal easier for you to use and to provide you with
              access to certain parts of the Portal.
            </li>
          </ul>

          <p>
            Neutral Fuels processes your personal data for the purposes outlined
            above where:
          </p>
          <ul>
            <li>
              The processing of your personal data is necessary to perform a
              contract or enter into a contract with you;
            </li>
            <li>
              The processing of your personal data is necessary for us to comply
              with legal and regulatory obligations;or
            </li>
            <li>
              The processing is necessary to support our legitimate interests in
              managing our business (or those of a third party) provided such
              interests are not overridden by your interests and rights.
            </li>
          </ul>

          <p>
            Where required by applicable law, where Neutral Fuels sends you
            direct marketing, we will obtain your consent for this, which you
            may withdraw at any time.
          </p>

          <h2 class="subtitle">Sharing Your Personal Data</h2>
          <p>
            Neutral Fuels may disclose your personal data to the following third
            parties:
          </p>
          <ul>
            <li>Other companies in our group of companies;</li>
            <li>
              Selected third parties to provide us with services, including
              companies that provide us with technical support and assistance in
              respect of the Site, companies that provide back-office services,
              companies that provide hosting services, or that track the Site’s
              activities and analytics, and companies engaged to market and
              distribute our products and/or services;
            </li>
            <li>
              Professional advisers, judicial, regulatory and law enforcement
              bodies; and
            </li>
            <li>In any other case with your consent.</li>
          </ul>

          <p>
            We may also share your personal data with third parties if we sell
            or buy any business or assets, in which case we may disclose
            personal data to the prospective seller or buyer of such business or
            assets, along with its professional advisers. If Neutral Fuels, or
            substantially all of its assets, are acquired by a third party,
            personal data held by us about customers will be one of the
            transferred assets.
          </p>
          <p>
            Otherwise, no third parties will have access to your personal data
            unless there is a legal obligation, or you give us permission to do
            so.
          </p>

          <h2 class="subtitle">‍Cross-Border Transfer of Your Data</h2>
          <p>
            We may instruct service providers, domestically or in a foreign
            country, to process your personal data on our behalf and in
            accordance with our instructions. We will retain control over your
            personal data and will remain fully responsible to you when engaging
            service providers.
          </p>
          <p>
            The level of personal data protection in the other jurisdictions may
            not provide an adequate level of protection from a UAE perspective.
            To address this, we have procedures and safeguards in place to
            ensure the protection of personal information. These procedures
            include contractual obligations to ensure that all such entities
            safeguard your personal information and use it only for the purposes
            that we have specified and communicated to you. When we transfer
            your information to other countries, we will use, share, and
            safeguard that information as described in this Portal Privacy
            Policy.
          </p>

          <h2 class="subtitle">‍Data Security and Retention</h2>
          <p>
            ‍Neutral Fuels are required to ensure that your personal data is
            accurate and maintained in a secure environment for a period of time
            no longer than necessary for the purposes for which we are
            processing your personal data. We will take all steps that are
            reasonably necessary to ensure that your personal data is treated
            securely and in accordance with this Privacy Policy and applicable
            data protection laws.
          </p>
          <p>
            Neutral Fuels generally keep personal data collected via the Portal
            in accordance with the timeframes set out in any relevant
            legislation which applies to the personal data provided to us.
            However, in some cases Neutral Fuels may need to keep personal data
            for longer (e.g., where Neutral Fuels are required to do so in
            accordance with legal, regulatory, tax or accounting requirements,
            or dealing with complaints, legal challenges, or prospective
            litigation).
          </p>

          <h2 class="subtitle">Accessing Your Personal Information</h2>
          <p>
            Various rights may be available to you, depending on the
            circumstances and the applicable law. We summarise key rights likely
            to be available to most data subjects:
          </p>
          <ul>
            <li>
              You may request information on the personal data held by us
              relating to you and the manner in which we have been processing
              your personal data, including information such as the categories
              of the data held, the purpose of the processing, controls and
              standards of the storage/archiving of the data, the safeguards put
              in place for cross-border processing and any actions taken
              regarding a breach that is a threat to the privacy and
              confidentiality of your personal data.
            </li>

            <li>
              You have the right to request inaccurate personal data is
              corrected.
            </li>

            <li>
              You have the right to restrict or stop processing of personal data
              if you contest the accuracy of the data, or where the processing
              is contrary to the purposes for which the data was given, or it
              contravenes the law.
            </li>

            <li>
              You have the right to request that personal data is erased if it
              is no longer necessary for the purposes it was collected, you have
              withdrawn your consent to the processing, there are no legitimate
              grounds to continue processing, or the processing does not comply
              with the law. This is subject to any rights Neutral Fuels may have
              to retain the information under the law.
            </li>

            <li>
              You can request that we cease processing personal data where it
              has been processed for direct marketing purposes, statistical
              survey purposes or been processed in contravention of the
              provisions of the law. This is subject to any rights Neutral Fuels
              Group may have to retain the information under the law.
            </li>
          </ul>

          <p>
            For Neutral Fuels Group to proceed with a request to amend, correct,
            erase, restrict or stop the processing of personal data (“Subject
            Access Request”), you will need to provide proof of your identity,
            unless we already hold the same. Proof of identity should be an
            official document containing your photograph such as a driving
            license, passport, or residency card. Proof of Identity Information
            will not be retained and will be used for the sole purpose of
            identity identification.
          </p>
          <p>
            Your Subject Access Request will be processed within 30 calendar
            days upon receipt your request and a proof of identity, where
            necessary. Copies of the personal data held by Neutral Fuels will be
            provided on an encrypted (password protected) file sent securely. To
            make a Subject Access Request please email
            <a href="mailto:subjectaccessrequest@neutralfuels.com"
              >subjectaccessrequest@neutralfuels.com</a
            >
            with the following information: ‍
          </p>
          <ul>
            <li>First and last name as per your passport</li>
            <li>Email address</li>
            <li>
              Your relationship to Neutral Fuels (for example, customer,
              supplier, or other relationship)
            </li>
            <li>
              Your reason for accessing the personal data (for example, access,
              rectification, or deletion)
            </li>
          </ul>

          <p>
            ‍If you are not satisfied with our response or believe we are
            processing your personal data not in accordance with the law, you
            can complain to the competent data protection supervisory authority
            in UAE.
          </p>

          <h2 class="subtitle">Confidentiality</h2>
          <p>
            ‍The information you provide may be confidential, and we will
            maintain such confidentiality and protect your information in
            accordance with this Portal Privacy Policy, our professional
            obligations and applicable law. We do not sell, rent, distribute, or
            otherwise make, personal data commercially available to any third
            party. We may share information with our service providers for the
            purpose set out in this Portal Privacy Policy
          </p>
          <p>
            For any questions, please email us at
            <a href="mailto:info@neutralfuels.com">info@neutralfuels.com</a>.
            Updated 2023.
          </p>
        </div>
      </div>
    </section>

    <!-- Footer Component -->
    <Footer />
  </div>
</template>
  
  <script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>
  
  <style>
/* Additional styling if needed */
</style>
  