<template>
  <Navbar></Navbar>
  <section class="section box container">
    <h1 class="title has-text-left">Customer Support</h1>
  </section>
  <Footer></Footer>
</template>
  
  <script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: { Navbar, Footer },
  setup() {},
};
</script>
  
  <style>
</style>