<template>
  <Navbar></Navbar>
  <section class="section">
    <div class="container">
      <div class="box">
        <h1 class="title">User Profile</h1>

        <!-- Success Notification -->
        <div v-if="successMessage" class="notification is-success">
          {{ successMessage }}
        </div>

        <!-- Error Notification -->
        <div v-if="errorMessage" class="notification is-danger">
          {{ errorMessage }}
        </div>
        <h2 class="subtitle">Edit Personal Information</h2>

        <!-- Edit Form -->
        <form @submit.prevent="updateProfile">
          <div class="columns">
            <div class="column">
              <!-- First Name -->
              <div class="field">
                <label class="label">First Name</label>
                <div class="control has-icons-left">
                  <input
                    class="input"
                    v-model="profile.first_name"
                    type="text"
                    placeholder="First Name"
                    required
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                  </span>
                </div>
              </div>

              <!-- Last Name -->
              <div class="field">
                <label class="label">Last Name</label>
                <div class="control has-icons-left">
                  <input
                    class="input"
                    v-model="profile.last_name"
                    type="text"
                    placeholder="Last Name"
                    required
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-user"></i>
                  </span>
                </div>
              </div>
            </div>

            <div class="column">
              <!-- Email -->
              <div class="field">
                <label class="label">Email</label>
                <div class="control has-icons-left">
                  <input
                    class="input"
                    v-model="profile.email"
                    type="email"
                    placeholder="Email"
                    disabled
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                </div>
              </div>

              <!-- Contact Number -->
              <div class="field">
                <label class="label">Contact Number</label>
                <div class="control has-icons-left">
                  <input
                    class="input"
                    v-model="profile.contact_number"
                    type="tel"
                    placeholder="Contact Number"
                  />
                  <span class="icon is-small is-left">
                    <i class="fas fa-phone"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Company Logo Upload -->
          <div class="field" v-if="profile.role == 'ACCOUNT_ADMIN'">
            <label class="label">Company Logo</label>
            <div class="file is-info has-name">
              <label class="file-label">
                <input
                  class="file-input"
                  type="file"
                  @change="onImageChange"
                  accept="image/*"
                />
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-upload"></i>
                  </span>
                  <span class="file-label">Choose a logo...</span>
                </span>
                <span class="file-name" v-if="imageFile">
                  {{ imageFile.name }}
                </span>
              </label>
            </div>
            <!-- Logo Preview -->
            <div class="logo-preview mt-4">
              <figure class="image is-128x128">
                <img :src="logoPreview" alt="Company Logo" class="is-rounded" />
              </figure>
            </div>
          </div>

          <!-- Update Button -->
          <div class="field is-grouped is-grouped-right mt-5">
            <div class="control">
              <button
                :disabled="loading"
                class="button is-primary is-rounded"
                type="submit"
              >
                <span v-if="!loading">Update Profile</span>
                <span v-else class="fas fa-spinner fa-spin"></span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
  <Footer></Footer>
</template>


<script>
import { computed, ref, watchEffect } from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { useStore } from "vuex";
import { db, storage } from "@/firebase/config";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable,
} from "firebase/storage";
import { doc, setDoc, updateDoc } from "firebase/firestore";

export default {
  components: { Navbar, Footer },
  name: "UserProfile",
  setup() {
    const store = useStore();
    const userProfile = computed(() => store.state.userProfile);
    const user = computed(() => store.state.user);

    const loading = ref(false); // Loading state for updateProfile
    const successMessage = ref("");
    const errorMessage = ref("");

    // Profile data
    const profile = ref({
      first_name: "",
      last_name: "",
      email: "",
      contact_number: "",
      customer_logo: null,
      role: "",
      primary_account: "",
    });
    const imageFile = ref(null);
    const logoPreview = ref("https://via.placeholder.com/128x128?text=No+Logo");

    const onImageChange = (event) => {
      imageFile.value = event.target.files[0];
      if (imageFile.value) {
        const reader = new FileReader();
        reader.onload = (e) => {
          logoPreview.value = e.target.result;
        };
        reader.readAsDataURL(imageFile.value);
      }
    };

    watchEffect(() => {
      if (userProfile.value) {
        profile.value = { ...userProfile.value };
        if (profile.value.customer_logo) {
          logoPreview.value = profile.value.customer_logo;
        }
      }
    });

    const updateProfile = async () => {
      loading.value = true;
      successMessage.value = "";
      errorMessage.value = "";

      try {
        if (imageFile.value) {
          const fileRef = storageRef(
            storage,
            `company-logos/${profile.value.primary_account}/logo.jpg`
          );
          const uploadTask = uploadBytesResumable(fileRef, imageFile.value);

          await new Promise((resolve, reject) => {
            uploadTask.on(
              "state_changed",
              null,
              (error) => reject(error),
              () => resolve()
            );
          });

          profile.value.customer_logo = await getDownloadURL(fileRef);
        }

        const docRef = doc(db, "Users", user.value.uid);
        await updateDoc(docRef, profile.value);

        if (profile.value.customer_logo) {
          const primaryAccountRef = doc(
            db,
            "Logos",
            profile.value.primary_account
          );
          await setDoc(primaryAccountRef, {
            url: profile.value.customer_logo,
          });
        }

        successMessage.value = "Profile updated successfully!";
      } catch (error) {
        console.error("Error updating profile:", error);
        errorMessage.value = "An error occurred while updating your profile.";
      } finally {
        loading.value = false;
        imageFile.value = null;
      }
    };

    return {
      profile,
      updateProfile,
      onImageChange,
      imageFile,
      loading,
      successMessage,
      errorMessage,
      logoPreview,
    };
  },
};
</script>


<style scoped>
.field {
  margin-bottom: 1rem;
}

.logo-preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-preview img {
  max-height: 128px;
  max-width: 128px;
}

.notification {
  margin-bottom: 1rem;
}
</style>
