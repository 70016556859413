<template>
  <div class="app-container">
    <div class="gradient-background"></div>
  </div>
  <router-view />
</template>

<style>
.gradient-background {
  /* background: linear-gradient(to bottom right, #89cff0, #00509e); */
  /* background: rgb(2, 0, 36); */
  background: rgb(20, 122, 190);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* To ensure the background stays behind other content */
}
.app-container {
  position: relative; /* This ensures the fixed gradient background is contained to this element */
}
</style>
