<template>
  <footer class="footer">
    <div class="container">
      <div class="columns is-centered is-flex-direction-column-mobile">
        <!-- Left column with logo -->
        <div class="column">
          <ul>
            <li>
              <img src="../assets/companyLogo.png" alt="Company Logo" />
            </li>
            <li>
              <img
                src="../assets/icv.png"
                alt="Company Logo"
                style="height: 50px"
              />
              <img
                class="ml-4"
                src="../assets/netzero.png"
                alt="Company Logo"
                style="height: 50px"
              />
            </li>
          </ul>
        </div>

        <!-- Middle column with links -->
        <div class="column">
          <ul>
            <li>
              <router-link to="/terms-and-conditions"
                >Terms and Conditions</router-link
              >
            </li>
            <li>
              <router-link to="/privacy-policy">Privacy Policy</router-link>
            </li>
            <li>
              <router-link to="/cookies-policy">Cookies Policy</router-link>
            </li>
          </ul>
        </div>

        <!-- Right column with company info -->
        <div class="column">
          <p>Neutral Fuels LLC</p>
          <p>
            Suite 415<br />Liberty House<br />
            DIFC
          </p>
        </div>
      </div>
      <div class="columns is-centered is-flex-direction-column-mobile">
        <div class="column">
          <p class="copyright has-text-centered">
            &copy; 2023. The Neutral Fuels Group of Companies. All rights
            reserved.
          </p>
          <p class="copyright has-text-centered">{{ versionNumber }}</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
export default {
  setup() {
    const store = useStore();
    return {
      versionNumber: computed(() => store.state.versionNumber),
    };
  },
};
</script>
    
    <style>
.footer {
  /* position: fixed; Fixed position */
  bottom: 0; /* Align to the bottom of the screen */
  width: 100%; /* Full width */
  background-color: #f5f5f5;
  padding: 20px 0;
  font-size: 14px;
  /* z-index: 1000; Ensure the footer is above other content if needed */
}

.footer .columns {
  justify-content: space-between;
}

.footer img {
  max-width: 150px; /* Adjust logo size */
}

.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer ul li {
  margin-bottom: 10px;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer .copyright {
  font-size: 12px;
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .footer .columns {
    flex-direction: column;
    align-items: center;
  }

  .footer .column {
    text-align: center;
    width: 100%;
  }

  .footer ul li {
    margin: 5px 0;
  }

  .footer img {
    max-width: 120px; /* You can adjust this value if needed */
    margin: 5px auto; /* Center the image and add margin */
  }
}
</style>
    