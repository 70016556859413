<template>
  <Navbar></Navbar>
  <section class="section" v-if="isLoading">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
    />
  </section>

  <section class="section box container tree-cards" v-if="!isLoading">
    <div v-if="userProfile">
      <div
        class="container is-flex is-align-items-center is-justify-content-space-between"
      >
        <h1 class="title">Dashboard</h1>
        <figure class="image is-96x96">
          <img
            v-if="userProfile.customer_logo != 'URL'"
            :src="userProfile.customer_logo"
            alt="Customer Logo"
          />
        </figure>
      </div>

      <div class="container">
        <div class="columns is-centered">
          <!-- Date Selection -->
          <div class="column is-narrow">
            <label class="label">Start Date:</label>
            <input type="date" v-model="startDate" class="input" />
          </div>
          <div class="column is-narrow">
            <label class="label">End Date:</label>
            <input type="date" v-model="endDate" class="input" />
          </div>

          <div class="column is-half is-narrow">
            <label v-if="userProfile.group" class="label">Vessel Filter:</label>
            <label v-else class="label">Truck Filter:</label>
            <VueMultiselect
              v-model="selectedEntities"
              :options="allEntities"
              :multiple="true"
              :close-on-select="true"
              :placeholder="
                userProfile.group ? 'Select a Vessel' : 'Select a Truck'
              "
            ></VueMultiselect>
          </div>
        </div>
      </div>
      <div id="cards-section">
        <div
          class="container box"
          style="margin: 1%"
          v-for="(customer, index) in userProfile.customers"
          :key="customer.customer_id"
        >
          <h1 class="title has-text-left">
            {{ customer.customer_name }} - {{ customer.site_name }}
          </h1>
          <!-- Cards -->
          <div class="columns is-multiline is-centered" v-if="cardData.length">
            <div
              class="column"
              :class="cardColumnClass(cardData[index].length)"
              v-for="(card, idx) in cardData[index]"
              :key="idx"
            >
              <div
                :class="['card', 'has-text-centered', 'uniform-card']"
                :style="{ backgroundColor: card.color }"
              >
                <div class="card-content">
                  <p class="dynamic-font-size">{{ card.calloutValue }}</p>
                  <p class="is-size-6">{{ card.title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="section box container" v-if="!isLoading">
    <div v-if="userProfile">
      <!-- Table -->

      <div
        class="container is-flex is-justify-content-space-between is-align-items-center"
      >
        <!-- Title -->
        <h1 class="title">Consumption Summary</h1>

        <!-- Button group -->
        <div class="buttons are-small">
          <router-link
            :to="{
              name: 'AllTransactions',

              params: {
                customer_id:
                  userProfile.customers[transactionsIndex].customer_id,
              },
            }"
          >
            <button class="button is-rounded">
              <span class="icon is-small">
                <i class="material-icons">visibility</i>
                <!-- Material icon for 'View' -->
              </span>
              <span class="ml-1">View All Transactions</span>
            </button>
          </router-link>

          <button
            class="button is-info is-rounded ml-3"
            @click="exportCardsToPDF"
          >
            <span class="icon is-small">
              <i class="material-icons">download</i>
              <!-- Material icon for 'Download' -->
            </span>
            <span>Download Accounts Summary</span>
          </button>
          <button class="button is-link is-rounded" @click="exportSummaryToCSV">
            <span class="icon is-small">
              <i class="material-icons">import_export</i>
              <!-- Material icon for 'Export' -->
            </span>
            <span>
              {{
                userProfile.group
                  ? "Export Vessel Summary (.csv)"
                  : "Export Truck Summary (.csv)"
              }}
            </span>
          </button>
          <button
            class="button is-link is-rounded"
            @click="fetchAllTransactions"
          >
            <span class="icon is-small">
              <i class="material-icons">import_export</i>
              <!-- Material icon for 'Export' -->
            </span>
            <span>Export All Transactions (.csv)</span>
          </button>
        </div>
      </div>

      <div class="select is-rounded mb-5">
        <select name="Select Account" id="" v-model="transactionsIndex">
          <option disabled value="">Select an account</option>
          <option
            :value="index"
            v-for="(customer, index) in userProfile.customers"
            :key="index"
          >
            {{ customer.customer_name }} - {{ customer.site_name }}
          </option>
        </select>
      </div>

      <Vue3EasyDataTable
        v-if="
          transactions[transactionsIndex] &&
          transactions[transactionsIndex].length
        "
        header-text-direction="center"
        body-text-direction="center"
        border-cell
        table-class-name="customize-table"
        :headers="headers"
        :items="transactions[transactionsIndex]"
      >
        <template #expand="item">
          <Vue3EasyDataTable
            border-cell
            header-text-direction="center"
            body-text-direction="center"
            table-class-name="customize-table"
            :headers="entityTransactionHeaders"
            :items="item.SaleTransactions"
          >
            <template #item-SaleDate="{ SaleDate }">
              {{ formatDate(SaleDate) }}
            </template>
          </Vue3EasyDataTable>
        </template>
      </Vue3EasyDataTable>
    </div>
  </section>

  <Footer></Footer>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { useStore } from "vuex";
import { computed, ref, watch, watchEffect } from "vue";
import VueMultiselect from "vue-multiselect";

import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  components: { Navbar, Footer, Vue3EasyDataTable, VueMultiselect, Loading },
  setup() {
    const store = useStore();
    const transactions = ref([]);
    const isLoading = ref(true); // Loading state
    const transactionsIndex = ref(0);
    const cardData = ref([]);
    const customer_ids = ref([]);
    const expandedRowIndex = ref(-1);
    const userProfile = computed(() => store.state.userProfile);
    const endDate = ref(new Date().toISOString().substr(0, 10));
    const selectedEntities = ref([]); // to store the selected trucks/vessels
    const allEntities = ref([]);
    const runTime = ref(0);

    const exportCardsToPDF = async () => {
      isLoading.value = true;
      const url = store.state.apiUrl + "generateAccountSummaryReport";
      const postBody = {
        startDate: startDate.value,
        endDate: endDate.value,
        selectedTrucks:
          selectedEntities.value.length > 0 ? selectedEntities.value : ["All"],
        customers: userProfile.value.customers,
        cardData: cardData.value,
      };
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(postBody),
        });
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        if (response.status === 400 || response.status === 405) {
          throw new Error("There was an error completing the request.");
        }
        const blob = await response.blob(); // Convert response to blob
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadURL;
        link.download = "report.png"; // Name of the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (err) {
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    const exportSummaryToCSV = () => {
      // Step 2: Convert data into CSV format
      const csvContent = convertToCSV(transactions.value.at(transactionsIndex));

      // Step 3: Trigger download
      const filename = userProfile.value.group
        ? "vessel-summary.csv"
        : "truck-summary.csv";
      triggerDownloadCSV(csvContent, filename);
    };

    const convertToCSV = (data) => {
      const headers = userProfile.value.group
        ? ["Vessel Name", "Quantity (L)", "Fuel Type"]
        : [
            "Truck Number",
            "Quantity (L)",
            "Total Distance (Km)",
            "Fuel Type",
            "Efficiency (Km/L)",
          ];

      const csvRows = [];

      // Add headers
      csvRows.push(headers.join(","));

      // Add data
      for (const item of data) {
        const row = userProfile.value.group
          ? [item.TruckNumber, item.TotalQuantityConsumed, item.FuelTypeName]
          : [
              item.TruckNumber,
              item.TotalQuantityConsumed,
              item.TotalDistanceRun,
              item.FuelTypeName,
              item.Efficiency,
            ];
        csvRows.push(row.join(","));
      }

      return csvRows.join("\n");
    };

    const triggerDownloadCSV = (csvContent, filename) => {
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", filename);

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    // Adjusted startDate to be the first day of the month, six months ago
    const startDate = ref(
      new Date(new Date().getFullYear(), new Date().getMonth() - 6, 2)
        .toISOString()
        .substr(0, 10)
    );

    const cardColors = ["#96c33d", "#96c33d", "#96c33d", "#96c33d", "#96c33d"];

    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      return date.toLocaleString();
    };

    const headers = computed(() => {
      const baseHeaders = [
        {
          value: "TruckNumber",
          text: userProfile.value.group ? "Vessel Name" : "Truck Number",
          sortable: true,
        },
        {
          value: "TotalQuantityConsumed",
          text: "Quantity (L)",
          sortable: true,
        },
        {
          value: "FuelTypeName",
          text: "Fuel Type",
        },
      ];

      if (!userProfile.value.group) {
        baseHeaders.splice(2, 0, {
          value: "TotalDistanceRun",
          text: "Total Distance (Km)",
          sortable: true,
        });

        baseHeaders.push({
          value: "Efficiency",
          text: "Efficiency (Km/L)",
          sortable: true,
        });
      }

      return baseHeaders;
    });

    const entityTransactionHeaders = [
      { value: "SaleDate", text: "Date", sortable: true },
      {
        value: "Volume",
        text: "Quantity (L)",
        sortable: true,
      },
      {
        value: "OdometerReading",
        text: "Meter Reading (Km)",
        sortable: true,
      },
      { value: "FuelType", text: "Fuel Type" },
    ];

    const convertAllTransactionToCSV = (data) => {
      const headers = [
        "Sale Date",
        userProfile.value.group ? "Vessel Name" : "Truck Number",
        userProfile.value.group ? "Engine Hours" : "Odometer Reading",
        "Quantity",
        "Fuel Type",
      ];

      const csvRows = [];

      // Add headers
      csvRows.push(headers.join(","));

      // Add data
      for (const item of data) {
        const row = [
          item.SaleDate,
          item.TruckNumber,
          item.OdometerReading,
          item.Quantity,
          item.FuelType,
        ];
        csvRows.push(row.join(","));
      }

      return csvRows.join("\n");
    };

    const fetchAllTransactions = async () => {
      const apiUrl = store.state.apiUrl + "getCustomerTransactions";
      const _customer = userProfile.value.customers.at(transactionsIndex.value);
      try {
        const response = await fetch(
          `${apiUrl}/${_customer.customer_id}?start_date=${startDate.value}&end_date=${endDate.value}`
        );

        if (response.ok) {
          const result = await response.json();
          const responseData = result[1].transactionsData;
          const csvdata = convertAllTransactionToCSV(responseData);
          // Step 3: Trigger download
          const filename = userProfile.value.group
            ? "vessel-transactions.csv"
            : "all-transactions.csv";
          triggerDownloadCSV(csvdata, filename);
        } else {
          console.error("Error fetching transactions");
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    const fetchTransactions = async (customer_ids) => {
      let accumulatedEntities = []; // Initialize a list to accumulate trucks/vessels for all customers
      const apiUrl = store.state.apiUrl + "getVolumetricDataForCustomer";
      try {
        for (let index = 0; index < customer_ids.length; index++) {
          const customer_id = customer_ids[index];
          const response = await fetch(
            `${apiUrl}/${customer_id}?start_date=${startDate.value}&end_date=${endDate.value}`
          );

          if (response.ok) {
            const result = await response.json();
            if (selectedEntities.value.length) {
              // Filter the transactions based on the selected entities
              transactions.value[index] = result[1].metricData.filter(
                (transaction) =>
                  selectedEntities.value.includes(transaction.TruckNumber)
              );
            } else {
              transactions.value[index] = result[1].metricData;
            }
            updateCustomerCards(transactions.value[index], index);

            // Populate the list of available entities
            const currentEntities = transactions.value[index].map(
              (transaction) => transaction.TruckNumber
            );
            accumulatedEntities = [...accumulatedEntities, ...currentEntities]; // Accumulate entities from this customer
          } else {
            console.error("Error fetching transactions");
          }
        }
        // After processing all customers, set the allEntities value with unique accumulated entities
        if (runTime.value == 0) {
          allEntities.value = [...new Set(accumulatedEntities)];
          runTime.value += 1;
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
      isLoading.value = false;
    };

    watchEffect(() => {
      if (userProfile.value) {
        let customerIds = [];
        for (
          let index = 0;
          index < userProfile.value.customers.length;
          index++
        ) {
          const customer_id = userProfile.value.customers[index].customer_id;
          customerIds.push(customer_id);
        }
        customer_ids.value = customerIds;
        fetchTransactions(customerIds);
      }
    });

    watch([startDate, endDate], () => fetchTransactions(customer_ids.value));
    watch(selectedEntities, () => fetchTransactions(customer_ids.value));

    const formatNumberWithSuffix = (num) => {
      if (num >= 1000000) return (num / 1000000).toFixed(2) + " Mil";
      else if (num >= 1000) return (num / 1000).toFixed(2) + " K";
      return num.toFixed(2);
    };

    const totalEntities = (_transactions) =>
      _transactions.length ? _transactions.length.toString() : "0";
    const totalDistance = (_transactions) =>
      _transactions.reduce(
        (sum, transaction) => sum + transaction.TotalDistanceRun,
        0
      );
    const totalQuantityConsumed = (_transactions) =>
      _transactions.reduce(
        (sum, transaction) => sum + transaction.TotalQuantityConsumed,
        0
      );
    const averageEfficiency = (_transactions, totalEntities) =>
      _transactions.reduce(
        (sum, transaction) => sum + transaction.Efficiency,
        0
      ) / totalEntities;
    const co2Savings = (totalDistance) => totalDistance * 0.151212196;

    const totalDeliveries = (_transactions) =>
      _transactions.reduce(
        (sum, transaction) => sum + (transaction.SaleTransactions?.length || 0),
        0
      );

    const toggleExpandedRow = (index) => {
      // If the clicked row is already expanded, collapse it
      // Otherwise, expand the clicked row
      expandedRowIndex.value = expandedRowIndex.value === index ? -1 : index;
    };

    const updateCustomerCards = (_transactions, index) => {
      const cards = [];
      // First card: Number of Trucks or Number of Vessels
      cards.push({
        title: userProfile.value.group
          ? "Number of Vessels"
          : "Number of Trucks",
        calloutValue: totalEntities(_transactions).toString(),
        color: cardColors[3],
      });

      // Include Total Distance and Efficiency cards only if userProfile.group is not TRUE
      if (!userProfile.value.group) {
        cards.push({
          title: "Total Distance\n(Km)",
          calloutValue: formatNumberWithSuffix(totalDistance(_transactions)),
          color: cardColors[1],
        });
        cards.push({
          title: "Efficiency\n(Km/L)",
          calloutValue: formatNumberWithSuffix(
            averageEfficiency(_transactions, totalEntities(_transactions))
          ),
          color: cardColors[0],
        });
      } else {
        // For vessels, include No of Deliveries card
        cards.push({
          title: "No of Deliveries",
          calloutValue: totalDeliveries(_transactions).toString(),
          color: cardColors[0],
        });
      }

      // Include Total Volume card
      cards.push({
        title: "Total Volume\n(L)",
        calloutValue: formatNumberWithSuffix(
          totalQuantityConsumed(_transactions)
        ),
        color: cardColors[2],
      });

      // Include CO2 Savings card
      cards.push({
        title: "CO2 Savings\n(Kg/Co2)",
        calloutValue: formatNumberWithSuffix(
          co2Savings(totalQuantityConsumed(_transactions))
        ),
        color: cardColors[4],
      });

      cardData.value[index] = cards;
    };

    const cardColumnClass = (numCards) => {
      if (numCards === 5) return "is-one-fifth";
      if (numCards === 4) return "is-one-quarter";
      if (numCards === 3) return "is-one-third";
      return "is-half";
    };

    return {
      transactions,
      startDate,
      endDate,
      isLoading,
      userProfile,
      transactionsIndex,
      cardData,
      toggleExpandedRow,
      expandedRowIndex,
      headers,
      entityTransactionHeaders,
      formatDate,
      selectedEntities,
      allEntities,
      exportCardsToPDF,
      exportSummaryToCSV,
      fetchAllTransactions,
      cardColumnClass,
    };
  },
};
</script>

<style>
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.css";

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.uniform-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.uniform-card .card-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dynamic-font-size {
  font-size: calc(1rem + 0.8vw);
  overflow: hidden;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tree-cards {
  background-image: url("../../assets/sky.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

.customize-table {
  --easy-table-border: 1px solid #c1cad4;
  --easy-table-row-border: 1px solid #c1cad4;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #c1cad4;
  --easy-table-header-background-color: rgb(20, 122, 190);

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #fff;
  --easy-table-body-even-row-background-color: #4c5d7a;

  --easy-table-body-row-font-color: #c0c7d2;
  --easy-table-body-row-background-color: #2d3a4f;
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #c1cad4;
  --easy-table-body-row-hover-background-color: rgb(20, 122, 190);

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #2d3a4f;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;

  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}

/* Round the table borders */
.vue3-easy-data-table {
  border-radius: 20px;
  overflow: hidden; /* This ensures the inner content respects the border-radius */
}

/* Additionally, if the table has a border, round its top-left and top-right corners */
.vue3-easy-data-table thead th:first-child {
  border-top-left-radius: 20px;
}

.vue3-easy-data-table thead th:last-child {
  border-top-right-radius: 20px;
}
</style>
