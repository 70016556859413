<template>
  <navbar></navbar>
  <section class="section box container">
    <div class="container is-flex is-justify-content-space-between">
      <h1 class="title has-text-left">Users</h1>
      <!-- Button to add a new user -->
      <button class="button is-small is-info is-rounded" @click="addUser">
        Add New User
      </button>
    </div>

    <!-- User Accounts List/Table -->

    <!-- Replace this with a data table or list to display user accounts -->
    <Vue3EasyDataTable
      :headers="userHeaders"
      :items="users"
      header-text-direction="center"
      body-text-direction="center"
      border-cell
    />
  </section>
  <Footer></Footer>

  <!-- User Creation Modal -->
  <div v-if="showModal" class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add New User</p>
        <button
          class="delete"
          aria-label="close"
          @click="showModal = false"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="label">First Name</label>
          <div class="control">
            <input
              class="input"
              v-model="newUser.first_name"
              type="text"
              placeholder="First Name"
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Last Name</label>
          <div class="control">
            <input
              class="input"
              v-model="newUser.last_name"
              type="text"
              placeholder="Last Name"
            />
          </div>
        </div>

        <!-- User Creation Form -->
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input
              class="input"
              v-model="newUser.email"
              type="email"
              placeholder="Email"
              required
            />
          </div>
        </div>
        <!-- <div class="field">
          <label class="label">Password</label>
          <div class="control">
            <input
              class="input"
              v-model="newUser.password"
              type="password"
              placeholder="Password"
              required
            />
          </div>
        </div> -->
        <div class="field">
          <label class="label">Contact Number</label>
          <div class="control">
            <input
              class="input"
              v-model="newUser.contact_number"
              type="contact"
              placeholder="Contact Number"
              required
            />
          </div>
        </div>
        <div class="field">
          <label class="label">Assign Accounts</label>
          <div class="control">
            <select v-model="newUser.customers" multiple required>
              <option
                v-for="customer in adminProfile.customers"
                :key="customer.customer_id"
                :value="customer"
              >
                {{ customer.customer_name }} - {{ customer.site_name }}
              </option>
            </select>
            <p class="is-size-7 has-text-danger">
              <i><b>**</b> Hold SHIFT to select multiple accounts <b>**</b></i>
            </p>
          </div>
        </div>
        <div class="field">
          <label class="label">User Role</label>
          <div class="select">
            <select v-model="newUser.role" required>
              <option value="" disabled>Select a user role</option>
              <!-- <option value="ACCOUNT_ADMIN">Account Admin</option> -->
              <option value="VIEWER">Viewer</option>
            </select>
          </div>
        </div>
        <!-- Display error if it exists -->
        <p class="has-text-danger" v-if="error">{{ error }}</p>
      </section>
      <footer class="modal-card-foot">
        <button
          class="button is-success is-small is-rounded"
          :disabled="isLoading"
          @click="createUser"
        >
          <!-- Show loading text if loading -->
          <span v-if="isLoading">Loading...</span>
          <span v-else>Save changes</span>
        </button>
        <button class="button is-small is-rounded" @click="showModal = false">
          Cancel
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, computed } from "vue";
import { db } from "@/firebase/config"; // adjust path to your Firebase config file
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useStore } from "vuex";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

export default {
  components: { Navbar, Footer, Vue3EasyDataTable },
  name: "Accounts",
  setup() {
    const users = ref([]);
    const usersRef = collection(db, "Users");
    const store = useStore();
    const adminProfile = computed(() => store.state.userProfile);
    const isLoading = ref(false); // Loading state for the request
    const error = ref(null); // To hold any error message
    const showModal = ref(false); // Controls whether the modal is displayed or not
    const newUser = ref({
      first_name: "",
      last_name: "",
      email: "",
      // password: "",
      contact_number: "",
      role: "",
      customers: [],
      // ... Add other fields if needed
    });

    const addUser = () => {
      showModal.value = true;
    };

    const fetchUsers = async () => {
      let fetchedUsers = {};

      for (let customer of adminProfile.value.customers) {
        const q = query(
          usersRef,
          where("customers", "array-contains", customer)
        );
        const snapshot = await getDocs(q);

        snapshot.forEach((doc) => {
          // Using a map (object) to ensure unique users
          fetchedUsers[doc.id] = doc.data();
        });
      }

      // Convert fetchedUsers object to an array
      users.value = Object.values(fetchedUsers);
      //console.log(users.value);
    };

    const createUser = async () => {
      isLoading.value = true; // Start the loading state
      error.value = null; // Reset the error

      const apiUrl = store.state.apiUrl + "create_viewer_account/";
      const customer = {
        first_name: newUser.value.first_name,
        last_name: newUser.value.last_name,
        email: newUser.value.email,
        customer_logo: adminProfile.value.customer_logo,
        primary_account: adminProfile.value.primary_account,
        // password: newUser.value.password,
        contact_number: newUser.value.contact_number,
        role: newUser.value.role,
        customers: newUser.value.customers,
      };

      try {
        // console.log("New User Profile =>", newUser.value);
        const response = await fetch(apiUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(customer),
        });
        if (!response.ok) {
          throw new Error("Something went wrong!");
        }
        if (response.status === 400 || response.status === 405) {
          throw new Error("There was an error completing the request.");
        }
        fetchUsers();
      } catch (err) {
        error.value = err.message; // Set the error message
        console.error("Error adding customer:", err);
      } finally {
        isLoading.value = false;
        newUser.first_name = "";
        newUser.last_name = "";
        newUser.email = "";
        // newUser.password = "";
        newUser.contact_number = "";
        newUser.customers = [];
        showModal.value = false; // Close the modal on successful request
      }
    };

    watchEffect(() => {
      if (adminProfile.value) {
        fetchUsers();
      }
    });

    const userHeaders = [
      { value: "first_name", text: "First Name" },
      { value: "last_name", text: "Last Name" },
      { value: "email", text: "Email" },
      { value: "contact_number", text: "Contact Number" },
      { value: "role", text: "User Role" },
    ];

    return {
      users,
      userHeaders,
      showModal,
      newUser,
      addUser,
      createUser,
      isLoading,
      error,
      adminProfile,
    };
  },
};
</script>

<style>
/* Add your styles similar to the dashboard's if needed */
</style>
