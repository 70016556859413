<template>
  <section class="hero is-fullheight has-background">
    <div class="hero-body">
      <div class="container has-text-centered">
        <div class="column is-4 is-offset-4">
          <div class="box">
            <figure class="avatar">
              <img src="../../assets/earth.png" />
            </figure>
            <h3 class="title has-text-black">Reset Password</h3>
            <div class="field">
              <div class="control">
                <input
                  class="input is-large is-rounded"
                  type="email"
                  placeholder="Email"
                  v-model="email"
                  autofocus=""
                />
              </div>
            </div>

            <button
              class="button is-block is-info is-large is-fullwidth is-rounded mt-6"
              @click="resetPassword"
            >
              Reset Password
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from "vue";
// import { auth } from './firebase.js';
// import { sendPasswordResetEmail } from "firebase/auth";

export default {
  setup() {
    const email = ref("");

    const resetPassword = async () => {
      try {
        // await sendPasswordResetEmail(auth, email.value);
        // Password reset email sent, inform the user
      } catch (error) {
        // Handle errors here
      }
    };

    return { email, resetPassword };
  },
};
</script>

<style scoped>
.hero.has-background {
  background: url("../../assets/sky.jpg") center center no-repeat;
  background-size: cover;
}
.box {
  margin-top: 5rem;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
  background-color: rgba(255, 255, 255, 0.5);
}
.avatar {
  margin-top: -70px;
  padding-bottom: 20px;
}
.avatar img {
  padding: 5px;
  /* background: #fff; */
  /* background-color: rgba(255, 255, 255, 0.5); */
  border-radius: 50%;
  width: 128px;
  height: 128px;
}
</style>
