
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, serverTimestamp } from 'firebase/firestore'
import { getStorage } from "firebase/storage";



const firebaseConfig = {
    apiKey: "AIzaSyCc5jCdBXX6rhYN7pxbzvkOf4uwY5dR45k",
    authDomain: "neutral-fuels-customer-portal.firebaseapp.com",
    projectId: "neutral-fuels-customer-portal",
    storageBucket: "neutral-fuels-customer-portal.appspot.com",
    messagingSenderId: "292093559148",
    appId: "1:292093559148:web:7180e84dd861f802f6f349",
    measurementId: "G-9N4X42TBFX"
};

// init firebase Backend
initializeApp(firebaseConfig)

// init services
const auth = getAuth()
const db = getFirestore()
const timestamp = serverTimestamp
const storage = getStorage()


export { db, auth, timestamp, storage }