<template>
  <Navbar></Navbar>
  <section class="section box container">
    <router-link to="/">
      <button class="button is-rounded is-small mb-5">
        <span class="material-icons">arrow_back_ios</span> Back
      </button>
    </router-link>

    <h1 class="title has-text-left">All Transactions</h1>

    <div class="columns is-centered">
      <!-- Date Selection -->
      <div class="column is-narrow">
        <label class="label">Start Date:</label>
        <input type="date" v-model="startDate" class="input" />
      </div>
      <div class="column is-narrow">
        <label class="label">End Date:</label>
        <input type="date" v-model="endDate" class="input" />
      </div>

      <!-- Entity Filter -->
      <div class="column is-half is-narrow">
        <label v-if="userProfile.group" class="label">Vessel Filter:</label>
        <label v-else class="label">Truck Filter:</label>
        <VueMultiselect
          v-model="selectedEntities"
          :options="allEntities"
          :multiple="true"
          :close-on-select="true"
          :placeholder="
            userProfile.group ? 'Select a Vessel' : 'Select a Truck'
          "
        ></VueMultiselect>
      </div>
    </div>

    <!-- Charts -->
    <div class="columns is-multiline">
      <div class="column box container uniform-card">
        <p class="is-size-4">
          Volume by {{ userProfile.group ? "Vessel" : "Truck" }}
        </p>
        <p>
          Fuel consumption for each {{ userProfile.group ? "vessel" : "truck" }}
        </p>
        <BarChart
          :chart-data="chartData"
          :options="chartOptions"
          :key="chartKey"
        />
      </div>
      <div class="column box container uniform-card">
        <p class="is-size-4">Monthly Volume</p>
        <p>Fuel consumption trend over months</p>
        <BarChart
          :chart-data="chartData2"
          :options="chartOptions"
          :key="chartKey"
        />
      </div>
    </div>

    <!-- Transactions Table -->
    <div class="box container">
      <h1 class="is-size-4 mb-5">Transaction Data</h1>
      <Vue3EasyDataTable
        v-if="transactions.length"
        header-text-direction="center"
        body-text-direction="center"
        border-cell
        table-class-name="customize-table"
        :headers="headers"
        :items="transactions"
      >
        <template #item-SaleDate="{ SaleDate }">
          {{ formatDate(SaleDate) }}
        </template>
      </Vue3EasyDataTable>
    </div>
  </section>
  <Footer></Footer>
</template>

<script>
import { ref, onMounted, toRefs, watch, computed } from "vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";
import BarChart from "@/components/BarChart.vue";
import { useStore } from "vuex";
import VueMultiselect from "vue-multiselect";
import "../../../node_modules/vue-multiselect/dist/vue-multiselect.css";

export default {
  name: "AllTransactions",
  props: ["customer_id"],
  components: {
    Vue3EasyDataTable,
    Navbar,
    Footer,
    BarChart,
    VueMultiselect,
  },
  setup(props) {
    const { customer_id } = toRefs(props);
    const transactions = ref([]);
    const endDate = ref(new Date().toISOString().substr(0, 10));
    // Adjusted startDate to be the first day of the month, six months ago
    const startDate = ref(
      new Date(new Date().getFullYear(), new Date().getMonth() - 6, 2)
        .toISOString()
        .substr(0, 10)
    );
    const chartKey = ref(0);
    const store = useStore();
    const userProfile = computed(() => store.state.userProfile);

    const selectedEntities = ref([]); // to store the selected trucks/vessels
    const allEntities = ref([]);

    const headers = computed(() => {
      const baseHeaders = [
        { value: "SaleDate", text: "Sale Date", sortable: true },
        { value: "FuelType", text: "Fuel Type", sortable: true },
        {
          value: "TruckNumber",
          text: userProfile.value.group ? "Vessel Name" : "Truck Number",
          sortable: true,
        },
        { value: "Quantity", text: "Volume (L)", sortable: true },
      ];

      if (!userProfile.value.group) {
        baseHeaders.splice(3, 0, {
          value: "OdometerReading",
          text: "Odometer Reading (Km)",
          sortable: true,
        });
      }

      return baseHeaders;
    });

    const formatDate = (isoDate) => {
      const date = new Date(isoDate);
      return date.toUTCString();
    };

    const fetchTransactions = async () => {
      const apiUrl = store.state.apiUrl + "getCustomerTransactions";

      try {
        const response = await fetch(
          `${apiUrl}/${customer_id.value}?start_date=${startDate.value}&end_date=${endDate.value}`
        );

        if (response.ok) {
          const result = await response.json();
          let fetchedTransactions = result[1].transactionsData;

          // Populate the list of available entities
          const entitiesSet = new Set();
          fetchedTransactions.forEach((transaction) => {
            entitiesSet.add(transaction.TruckNumber);
          });
          allEntities.value = Array.from(entitiesSet);

          // Filter the transactions based on selectedEntities
          if (selectedEntities.value.length) {
            transactions.value = fetchedTransactions.filter((transaction) =>
              selectedEntities.value.includes(transaction.TruckNumber)
            );
          } else {
            transactions.value = fetchedTransactions;
          }

          // Update the charts with the filtered transactions
          updateCharts(transactions.value);
        } else {
          console.error("Error fetching transactions");
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    watch([startDate, endDate, selectedEntities], () => fetchTransactions());

    // Chart data and options
    const chartData = ref({
      labels: [],
      datasets: [
        {
          label: "Total Volume",
          backgroundColor: "#00d4ff",
          data: [],
        },
      ],
    });

    const chartData2 = ref({
      labels: [],
      datasets: [
        {
          label: "Total Volume",
          backgroundColor: "#020024",
          data: [],
        },
      ],
    });

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          beginAtZero: true,
        },
        y: {
          beginAtZero: true,
        },
      },
    };

    const updateCharts = (newTransactions) => {
      const groupedData = {}; // This will group the data by Truck Number or Vessel Name
      const groupedData2 = {}; // This will group the data by date or month

      newTransactions.forEach((transaction) => {
        const entity = transaction.TruckNumber;
        const date = new Date(transaction.SaleDate);
        const monthName = `${date.toLocaleString("default", {
          month: "long",
        })} ${date.getFullYear()}`;

        if (!groupedData[entity]) groupedData[entity] = 0;
        if (!groupedData2[monthName]) groupedData2[monthName] = 0;

        groupedData[entity] += transaction.Quantity;
        groupedData2[monthName] += transaction.Quantity;
      });

      chartData.value.labels = Object.keys(groupedData);
      chartData.value.datasets[0].data = Object.values(groupedData);

      chartData2.value.labels = Object.keys(groupedData2);
      chartData2.value.datasets[0].data = Object.values(groupedData2);
      chartKey.value++;
    };

    onMounted(fetchTransactions);

    return {
      transactions,
      startDate,
      endDate,
      headers,
      formatDate,
      chartData,
      chartOptions,
      chartKey,
      chartData2,
      selectedEntities,
      allEntities,
      userProfile,
    };
  },
};
</script>

<style>
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.css";

.uniform-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 1%;
}
</style>
