<template>
  <Navbar></Navbar>
  <section class="section">
    <div class="container box">
      <h1 class="title">Terms and Conditions</h1>
      <div class="content">
        <p>
          You agree to these terms and conditions (the “Terms”) by accessing the
          Neutral Fuels Customer Portal www.myportal.neutralfuels.com (the
          “Portal”). The Portal is operated by or on behalf of Neutral Fuels LLC
          or its companies, subsidiaries and/or affiliates (collectively
          “Neutral Fuels”, “we”, “us”, or “our”). If you do not agree with the
          terms, you must not use the Portal. Neutral Fuels is a company
          established under the laws of Dubai, United Arab Emirates and our
          registered office is at Suite 415, Liberty House, DIFC, Dubai, United
          Arab Emirates.
        </p>
        <p>
          Neutral Fuels is not liable for the content of the Portal. Please
          ensure that you read the Terms carefully and pay particular attention
          to the Limitation of Liability and Disclaimer sections of these Terms.
          Together with our Portal’s privacy policy (the “Portal Privacy
          Policy”), cookies policy (“the Portal Cookies Policy”) they govern our
          relationship with you in relation to the Portal.
        </p>
        <p>
          By using the Portal in any way, you will signify your agreement with
          these Terms and to be bound by them. If you have any questions about
          them or do not wish to accept them, please contact us at
          <a href="mailto:info@neutralfuels.com">info@neutralfuels.com</a>
          before using the Portal. Using or accessing the Portal indicates your
          acceptance of these Terms.
        </p>

        <h2 class="subtitle">‍Use of the Portal</h2>
        <p>
          ‍Neutral Fuels have made the Portal available to you for your use as
          our customer. Neutral Fuels reserve the right to withdraw or modify
          the Portal at any time. Neutral Fuels may add new features and modify
          or even discontinue existing features without notice to you and in our
          sole discretion. Any amendments shall come into effect immediately.
        </p>
        <p>
          You will be required to register with the Portal to access certain
          features. You agree to provide accurate, current, and complete
          information during the registration process and to update such
          information to keep it accurate, current, and complete.
        </p>
        <p>
          You are responsible for maintaining the confidentiality of your
          password and are responsible for all activities that occur under your
          account. You agree to notify us immediately of any unauthorized use of
          your account.
        </p>
        <p>
          We may terminate or suspend your account and bar access to the Portal
          immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of the Terms.
        </p>

        <h2 class="subtitle">Intellectual Property</h2>
        <p>
          The Portal and all the materials contained in it are protected by
          intellectual property rights, including copyright, and either belong
          to us or are licensed to us to use. Materials include, but are not
          limited to, the design, layout, look, appearance, graphics and
          documents on the Portal, and other content. You may not copy,
          redistribute, republish or otherwise make the materials on the Portal
          available to anyone else without our consent in writing.
        </p>
        <p>
          You may print or download materials from the Portal for your use
          provided that:
        </p>
        <ul>
          <li>no materials are modified in any way;·</li>
          <li>no graphics are used separately from accompanying text;</li>
          <li>
            our copyright and trade mark notices appear in all copies and you
            acknowledge the Portal as the source of the material; and
          </li>
          <li>
            if you have our permission to provide these materials to another
            person, you ensure they are made aware of these restrictions.
          </li>
        </ul>

        <h2 class="subtitle">No Offer</h2>
        <p>
          Nothing on the Portal is intended to be nor should be construed as an
          offer to enter into a contractual relationship with you or anyone
          else, except for these Terms which govern the relationship between us
          in relation to your use of the Portal. All services offered by us are
          subject to a written contract.
        </p>

        <h2 class="subtitle">Transmission of Information</h2>
        <p>
          Your attention is drawn to the fact that information transmitted via
          the Internet is susceptible to monitoring and interception.
        </p>
        <p>
          You will bear all risk of transmitting information in this manner.
          Neutral Fuels will not be liable for any loss, harm or damage suffered
          by you as a result of transmitting information to us. Neutral Fuels
          reserve the right to request independent verification of any
          information transmitted via e-mail.
        </p>

        <h2 class="subtitle">Limitation of Liability</h2>
        <p>
          Your use of and reliance on the Portal is entirely at your own risk.
          The Portal is provided “As Is” and “As Available”.
        </p>
        <p>
          Whilst Neutral Fuels tries to ensure the information contained on the
          Portal is accurate and up to date, Neutral Fuels cannot be responsible
          for any inaccuracies in the information. As a result, you should not
          rely on this information, and Neutral Fuels recommends that you take
          further advice or seek further guidance before taking any action based
          on the information contained on the Portal. We do not accept any
          liability for any acts or omissions resulting from your decision or
          opinion formed on the basis of your use of the Portal. Use of the
          Portal is at your sole risk.
        </p>
        <p>
          We do not guarantee that the Portal will be compatible with all or any
          hardware and software which you may use. We do not guarantee that the
          Portal will be available all the time or at any specific time, that
          access will be uninterrupted, that there will be no delays, failure,
          errors or omissions or loss of transmitted information. We shall not
          be liable to you for any physical loss or damage to your computer as a
          result of your use of the Portal, including any damage arising as a
          result of a virus. You have sole responsibility for adequate
          protection and back up of data and/or equipment.
        </p>
        <p>
          Neutral Fuels shall not be liable in respect of any loss, damage or
          damages however arising and whatever the cause relating to your use of
          or reliance on the information contained on the Portal, your access to
          the Portal, or from your inability to access the Portal. To the extent
          permitted by UAE law, Neutral Fuels shall not be liable for (whether
          direct or indirect) any loss of profit, loss of opportunity, loss of
          business, lost data, business interruption, indirect, incidental,
          special, or consequential loss arising out of or in any way connected
          with the use of any information on the Portal or otherwise arising out
          of the use of the Portal and all warranties, terms and conditions with
          regard to such information and services, whether express or implied
          arising under law or otherwise are hereby excluded.
        </p>
        <p>The Terms do not exclude our liability (if any) to you for:</p>
        <ul>
          <li>personal injury or death resulting from our negligence;</li>
          <li>fraud;</li>
          <li>
            any matter which it would be illegal for us to exclude or to attempt
            to exclude our liability.
          </li>
        </ul>

        <h2 class="subtitle">Disclaimer</h2>
        <p>
          The Portal, including all content, software, functions, materials, and
          information made available on, provided in connection with, or
          accessible through the Portal, are provided "as is." We do not warrant
          that the functions contained in the Portal will be uninterrupted or
          error free.
        </p>
        <p>
          To the fullest extent permissible by law, we make no representation or
          warranty of any kind whatsoever for the Portal, including the
          services, content, materials, information and functions made
          accessible by the Portal, or for any breach of security associated
          with the transmission of sensitive information through the Portal.
        </p>
        <p>
          We will not be responsible for any service interruptions, including
          system failures or other interruptions that may affect the Portal.
        </p>
        <p>
          The Portal may include technical, typographical, or other inaccuracies
          and you are urged to contact us to confirm all information contained
          on the Portal before placing any reliance on it. Changes are
          periodically made to the information on the Portal and these changes
          will be incorporated in new editions of the Portal. Neutral Fuels
          reserve the right to alter or amend any criteria or information set
          out in the Portal without notice
        </p>

        <h2 class="subtitle">Privacy</h2>
        <p>
          Neutral Fuels may collect personal data from you when if use the
          Portal to communicate with us. In addition to this personal data,
          Neutral Fuels collects other non-personal information. In respect of
          each visitor to this Portal, the Portal’s server will automatically
          recognise and store their Internet Protocol address, but not any
          personally identifiable information. In addition, the Portal uses
          cookies. Most web browsers are set by default to accept cookies;
          however, you may set your browser to either prompt or refuse cookies.
          Please note that rejecting cookies will restrict the functionality of
          the Portal as a whole.
        </p>
        <p>
          Neutral Fuels uses the non-personal information collected for tracking
          the statistics of this Portal, which allows us to better understand
          our users and improve the layout and functionality of the Portal.
        </p>
        <p>
          You can find further information on these matters in our Portal
          Privacy Policy and Portal Cookies Policy.
        </p>

        <h2 class="subtitle">Changes to these Terms</h2>
        <p>
          Neutral Fuels reserve the right, in our sole discretion, to amend
          these Terms, the Portal Privacy Policy and the Portal Cookies Policy,
          in any way and from time to time. Neutral Fuels will publish the
          amended terms on the Portal.
        </p>
        <p>
          It is your responsibility to review these Terms regularly and to
          ensure that you agree with any amendments to these Terms. If you do
          not agree with any amendments to these Terms, please do not continue
          to use the Portal.
        </p>

        <h2 class="subtitle">General</h2>
        <p>
          We may transfer our rights and obligations under the Terms to another
          organisation.
        </p>
        <p>
          You may not transfer your rights or your obligations under the Terms
          to another person.
        </p>
        <p>
          This agreement does not give rise to any rights to any third parties.
        </p>
        <p>
          Even if we delay in enforcing this contract, we can still enforce it
          later. If we do not insist immediately that you do anything you are
          required to do under the Terms, or if we delay in taking steps against
          you in respect of your breaking this contract, that will not mean that
          you do not have to do those things and it will not prevent us taking
          steps against you at a later date.
        </p>
        <p>
          If any part of the Terms, the Portal Privacy Policy or the Portal
          Cookies Policy is found by any court or other competent authority to
          be invalid, unlawful, or unenforceable then such part shall be severed
          from the rest of the terms and conditions which shall continue to be
          valid and enforceable to the fullest extent permitted by law.
        </p>
        <p>
          Please note that the Terms, their subject matter, and their formation,
          are governed by the laws of Dubai and the federal laws of the UAE.
          Courts of Dubai will have exclusive jurisdiction.
        </p>
        <p>
          For any questions, please email us at
          <a href="mailto:info@neutralfuels.com">info@neutralfuels.com</a>.
          Updated 2023.
        </p>
      </div>
    </div>
  </section>
  <Footer></Footer>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: { Navbar, Footer },
  setup() {},
};
</script>
  
  <style>
/* Additional styling if needed */
</style>
  